import { useEffect, useState } from 'react'
import { ApiService } from '../../lib/api'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  handleLogin: () => void
  handleError: () => void
  handleOpenLoginWithEmailModal: () => void
  setToken: (val: string) => void
}

interface LoginData {
    email: string
    password: string
}

export const LoginModal = ({ isOpen, handleClose, handleLogin, handleError, setToken, handleOpenLoginWithEmailModal }: Props) => {
    const [passwordShow, setPasswordShow] = useState(false)
    const [submitAvail, setToggleSubmitAvail] = useState(false)

    const togglePasswordVisibility = () => {
        setPasswordShow(!passwordShow)
    }

    const [data, setData] = useState<LoginData>({
        email: '',
        password: ''
    })

    useEffect(() => {
        if(data.email && data.password) {
            setToggleSubmitAvail(true);
        } else {
            setToggleSubmitAvail(false);
        }
    }, [data])

    const handleChange = (e: { target: { value: any; name: any } }) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
    };


    const apiService = new ApiService();

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        setToggleSubmitAvail(false);
        e.preventDefault();
        const loginData = {
            username: data.email,
            password: data.password
        }

        try{
            const res = await apiService.login(loginData);
            localStorage.setItem('phoodle-user-token', res.data);
            setToken(res.data);
            handleClose();
            handleLogin();
            setToggleSubmitAvail(true);
        }
        catch(e){
            console.error(e);
            setToggleSubmitAvail(true);
            handleError();
        }
    }

  return (
    <BaseModal title="LOGIN" isOpen={isOpen} handleClose={handleClose}>
        <p className='font-poppins font-light mb-3 dark:text-white'>
            Log in to view your saved statistics.
        </p>
        <form onSubmit={handleSubmit}>
            <label htmlFor="email">
                <div className='flex flex-col'>
                    <div className='text-left'>
                        <span className='font-bold dark:text-white'>EMAIL</span>
                    </div>
                    <input
                        type="email"
                        name="email"
                        className='p-1 border-2 rounded-md'
                        value={data.email}
                        onChange={handleChange}
                    />
                </div>
            </label>
            <label htmlFor="password">
                <div className='flex flex-col mb-2'>
                    <div className='text-left'>
                        <span className='font-bold dark:text-white'>PASSWORD</span>
                    </div>
                    <div className='flex relative'>
                        <input
                            type={passwordShow ? "text" : "password"}
                            name="password"
                            className='p-1 w-full border-2 rounded-md text-black'
                            value={data.password}
                            onChange={handleChange}
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                            {passwordShow ? 
                                <button type="button" onClick={togglePasswordVisibility}>
                                    <svg fill="none" className="h-6 text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                        <path fill="currentColor"
                                            d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z">
                                        </path>
                                    </svg>
                                </button>
                                :
                                <button type="button" onClick={togglePasswordVisibility}>
                                    <svg className="h-6 text-gray-700" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                        <path fill="currentColor"
                                            d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z">
                                        </path>
                                    </svg>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </label>
            <div className='text-blueButton underline cursor-pointer pb-4 hover:text-darkerGreen' onClick={() => {
                handleClose();
                handleOpenLoginWithEmailModal();
            }}>
              Forgot Password?
            </div>
            <button 
                type="button"
                className="disabled:bg-slate-400 disabled:ring-0 mt-1 w-1/2 rounded-md border border-transparent shadow-sm px-4 py-3 bg-buttonGreen text-sm font-bold text-black !outline-none ring-4 ring-offset-2 ring-buttonClick sm:text-sm"
                onClick={handleSubmit}
                disabled={!submitAvail}
                >SUBMIT</button>
        </form>
    </BaseModal>
  )
}

