import { GameStats } from '../../lib/localStorage'

type Props = {
  gameStats: GameStats
}

const StatItem = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => {
  return (
    <div className="items-center justify-center mx-2 w-1/4 dark:text-white">
      <div className="text-sm font-black whitespace-nowrap">{label}</div>
      <div className="text-lg font-digital text-buttonGreen rounded-lg p-1 px-2 bg-black">{value}</div>
    </div>
  )
}

export const StatBar = ({ gameStats }: Props) => {
  return (
    <div className="flex justify-center my-2">
      <StatItem label="PLAYED" value={gameStats.totalGames} />
      <StatItem label="WIN %" value={`${gameStats.successRate}%`} />
      <StatItem label="STREAK" value={gameStats.currentStreak} />
      <StatItem label="MAX" value={gameStats.bestStreak} />
    </div>
  )
}
