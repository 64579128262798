import { MAX_CHALLENGES } from '../constants/settings'
import {
  GameStats,
  loadStatsFromLocalStorage,
  saveStatsToLocalStorage,
} from './localStorage'

// In stats array elements 0-5 are successes in 1-6 trys

interface UserAPIData {
  _id: string
  email: string
  firstName: string
  lastName: string
  allowsEmail: boolean
  isSuperUser: boolean
  gamesFailed: number
  successRate: number
  currentStreak: number
  bestStreak: number
  winDistribution: number[]
}

export const addStatsForCompletedGame = (
  gameStats: GameStats,
  count: number
) => {
  // Count is number of incorrect guesses before end.
  const stats = { ...gameStats }

  stats.totalGames += 1

  if (count >= MAX_CHALLENGES) {
    // A fail situation
    stats.currentStreak = 0
    stats.gamesFailed += 1
  } else {
    stats.winDistribution[count] += 1
    stats.currentStreak += 1

    if (stats.bestStreak < stats.currentStreak) {
      stats.bestStreak = stats.currentStreak
    }
  }

  stats.successRate = getSuccessRate(stats)

  saveStatsToLocalStorage(stats)
  return stats
}

export const addAPIStatsForCompletedGame = (
  apiStats: UserAPIData,
  count: number
) => {
    //API Stats update
  const userToken = localStorage.getItem('phoodle-user-token')
  if(userToken && apiStats){
    const apiStatSpread = { ...apiStats }
    if (count >= MAX_CHALLENGES) {
      // A fail situation
      apiStatSpread.currentStreak = 0
      apiStatSpread.gamesFailed += 1
    } else {
      apiStatSpread.winDistribution[count] += 1
      apiStatSpread.currentStreak += 1
  
      if (apiStatSpread.bestStreak < apiStatSpread.currentStreak) {
        apiStatSpread.bestStreak = apiStatSpread.currentStreak
      }
    }
  
    apiStatSpread.successRate = getAPISuccessRate(apiStatSpread)

    const statsForApi = [
      {winDistribution: {Assign: apiStatSpread.winDistribution}}, 
      {gamesFailed: {Assign: apiStatSpread.gamesFailed}}, 
      {currentStreak: {Assign: apiStatSpread.currentStreak}}, 
      {bestStreak: {Assign: apiStatSpread.bestStreak}}, 
      {successRate: {Assign: apiStatSpread.successRate}}
    ]

    const stats = {
      winDistribution: apiStatSpread.winDistribution,
      gamesFailed: apiStatSpread.gamesFailed,
      currentStreak: apiStatSpread.currentStreak,
      bestStreak: apiStatSpread.bestStreak,
      successRate: apiStatSpread.successRate
    }

    return {statsForApi, stats}
  }
}

const defaultStats: GameStats = {
  winDistribution: Array.from(new Array(MAX_CHALLENGES), () => 0),
  gamesFailed: 0,
  currentStreak: 0,
  bestStreak: 0,
  totalGames: 0,
  successRate: 0,
}

export const loadStats = () => {
  return loadStatsFromLocalStorage() || defaultStats
}

const getSuccessRate = (gameStats: GameStats) => {
  const { totalGames, gamesFailed } = gameStats

  return Math.round(
    (100 * (totalGames - gamesFailed)) / Math.max(totalGames, 1)
  )
}

const getAPISuccessRate = (gameStats: UserAPIData) => {
  const { winDistribution, gamesFailed } = gameStats
  const failed = gamesFailed
  const success = winDistribution.reduce((partialSum, a) => partialSum + a, 0)

  const totalGames = failed + success

  return Math.round(
    (100 * (totalGames - gamesFailed)) / Math.max(totalGames, 1)
  )
}