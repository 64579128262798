import { VALID_GUESSES } from '../constants/validGuesses'
import { default as GraphemeSplitter } from 'grapheme-splitter'

export const isWordInWordList = (word: string) => {
  return (
    VALID_GUESSES.includes(localeAwareLowerCase(word))
  )
}

export const unicodeSplit = (word: string) => {
  return new GraphemeSplitter().splitGraphemes(word)
}

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length
}

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase()
}

export const getWordOfDay = () => {
  // Use local dates but handle the calculations more precisely
  const epoch = new Date('May 8, 2022')
  epoch.setHours(0, 0, 0, 0)
  
  const now = new Date()
  // Get the time zone offset at epoch
  const epochOffset = epoch.getTimezoneOffset()
  // Get the time zone offset now
  const currentOffset = now.getTimezoneOffset()
  
  // Adjust for timezone differences including DST
  const offsetDiff = (currentOffset - epochOffset) * 60 * 1000
  
  now.setHours(0, 0, 0, 0)
  const nextDay = new Date(now)
  nextDay.setDate(nextDay.getDate() + 1)
  
  const msInDay = 86400000
  const index = Math.round((now.getTime() - epoch.getTime() + offsetDiff) / msInDay)

  return {
    solutionIndex: index,
    tomorrow: nextDay.getTime(),
  }
}

export const { solutionIndex, tomorrow } = getWordOfDay()
