import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/outline'
import { ReactComponent as ForkSpoon} from "../../images/Fork_Spoon_Icon.svg"
import classnames from 'classnames'

type Props = {
  title: string
  children: React.ReactNode
  isOpen: boolean
  handleClose: () => void
  isFact?: boolean
  className?: boolean
  isPopUp?: boolean
  handlePopUp?: () => void
}

export const BaseModal = ({ title, children, isOpen, handleClose, isFact, isPopUp, handlePopUp, className }: Props) => {
  const classes = classnames(
    `text-4xl pb-3 font-poppins my-5 leading-6 tracking-wide font-medium text-gray-900 dark:text-white`,
    {
      "!text-2xl !my-2 !font-medium" : className
    }
  )

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="flex items-center justify-center min-h-screen py-10 px-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={`${isPopUp ? 'bg-buttonGreen' : 'bg-white dark:bg-gray-800'} inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6`}>
              {isPopUp && handlePopUp ?
              <div className="absolute right-4 top-4">
                <XCircleIcon
                  className="h-6 w-6 cursor-pointer dark:stroke-black"
                  onClick={() => {handlePopUp(); handleClose();}}
                /> 
              </div> 
              : 
              <div className="absolute right-4 top-4">
                <XCircleIcon
                  className="h-6 w-6 cursor-pointer dark:stroke-white"
                  onClick={() => handleClose()}
                />
              </div> 
              } 
              <div>
                {isFact ?
                <div className="text-center">
                  <Dialog.Title
                    as="h3"
                    className={classes}
                  >
                    {title}
                  </Dialog.Title>
                  <div className='flex flex-row justify-center items-center'>
                    <div className="h-[1px] bg-buttonGreen w-2/5 mx-2"  />
                    <ForkSpoon className="h-9 w-9 mx-2"  />
                    <div className="h-[1px] bg-buttonGreen w-2/5 mx-2"  />
                  </div>
                  <div className="mt-2 pt-2">{children}</div>
                </div>
                : isPopUp ? 
                <div className="text-center">
                  <div className="mt-2 pt-2">{children}</div>
                </div>
                :
                <div className='text-center divide-y-2'>
                  <Dialog.Title
                    as="h3"
                    className={`text-2xl pb-0 font-poppins font-medium my-2 leading-6 tracking-wide text-gray-900 dark:text-gray-100`}
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2 pt-2">{children}</div>
                </div>
                }
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
