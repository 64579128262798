import { useEffect } from 'react'
import Countdown from 'react-countdown'
import { GameStats } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import {
  NEW_WORD_TEXT,
  SHARE_TEXT,
} from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
  description: string
  link: string
  linkName: string
  imgSrc: string
  solution: string
}

export const FactModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  numberOfGuessesMade,
  description,
  link,
  linkName,
  imgSrc,
  solution
}: Props) => {

  useEffect(() => {
    // Ensure gtag is available
    if (typeof window !== 'undefined' && (window as any).gtag) {
      (window as any).gtag('js', new Date());
      (window as any).gtag('config', 'G-PGCV3ZPNVT');
    }
  }, []);

  const handleLinkClick = ( eventName: string, phoodleFact: boolean ) => {
    if (typeof window !== 'undefined' && (window as any).gtag) {
      if( phoodleFact ) {
        const eventParams = {
          'event_category': 'engagement',
          'event_label': linkName || 'View a Recipe',
          'value': 1
        };
        (window as any).gtag('event', eventName, eventParams);
        console.log('GA event sent:', eventName, eventParams);

        // Open the link in a new tab
        window.open(link, '_blank', 'noopener,noreferrer');
      } else {
        const eventParams = {
          'event_category': 'engagement',
          'event_label': 'Share Game Result',
          'value': 1
        };
        (window as any).gtag('event', eventName, eventParams);
        console.log('GA event sent:', eventName, eventParams);
      }
    } else {
      console.warn('Google Analytics not available');
    }
  };
  
  if (!isGameLost && !isGameWon) {
    return (
      <BaseModal
        title="SOLUTION"
        isOpen={isOpen}
        handleClose={handleClose}
        isFact={true}
        className={true}
      >
        <div className='font-poppins py-3 font-light dark:text-white'>
          <p>After playing the game a Phoodle Fact about the word will appear here</p>
        </div>
      </BaseModal>
    )
  }
  return (
    <BaseModal
      title={solution.toUpperCase()}
      isOpen={isOpen}
      handleClose={handleClose}
      isFact={true}
    > 
      <div className='divide-y divide-solid divide-buttonGreen'>
        <div className='pb-4'>
          <h3 className='font-bold dark:text-white'>PHOODLE FACT</h3>
          <div className='dark:text-white py-3 leading-5 font-light font-poppins'>
            {description.charAt(0) === '<' ? (
              <span dangerouslySetInnerHTML={{ __html: description }}></span>
            ) : (
              <span>{description}</span>
            )}
          </div>
          {imgSrc.length >= 1 && 
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img 
                src={imgSrc}
                alt={linkName ? linkName : 'Link Image'}
                style={{maxHeight: '300px'}}
              />
            </div>
          }
          {link.length >= 1 &&
            <div className='dark:text-white py-3 font-light font-poppins'>
              <a href={link} target="_blank" rel="noopener noreferrer" className='outline-none'>
                <button
                  onClick={() => { handleLinkClick('Phoodle_Fact_Link', true) }}
                  type="button"
                  className="font-poppins mt-1 w-1/2 rounded-md border border-transparent shadow-sm px-4 py-3 bg-blueButton text-sm font-bold text-black !outline-none ring-4 ring-offset-2 ring-blueButton sm:text-sm">
                  {linkName ? linkName : 'VIEW A RECIPE'}
                </button>
              </a>
            </div>
          }
        </div>
        <div>
          {(isGameLost || isGameWon) && (
            <div className="mt-5 sm:mt-6 columns-2 font-poppins dark:text-white">
              <div className='pb-3'>
                <h5 className='pb-1'>{NEW_WORD_TEXT}</h5>
                <Countdown
                  className="text-lg font-digital text-buttonGreen rounded-lg p-1 px-3 bg-black"
                  date={tomorrow}
                  daysInHours={true}
                />
              </div>
              <button
                type="button"
                className="mt-1 w-full rounded-md border border-transparent shadow-sm px-4 py-3 bg-buttonGreen text-sm font-bold text-black !outline-none ring-4 ring-offset-2 ring-buttonClick sm:text-sm"
                onClick={() => {
                  handleLinkClick('Share_Game_Score', false);
                  shareStatus(
                    guesses,
                    isGameLost,
                    isHardMode,
                    isDarkMode,
                    isHighContrastMode,
                    handleShareToClipboard,
                    solution
                  )
                }}
              >
                {SHARE_TEXT}
              </button>
            </div>
          )}
        </div>
      </div>
    </BaseModal>
  )
}
