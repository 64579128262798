import { GameStats } from '../../lib/localStorage'
import { ReactComponent as Crust} from "../../images/Phoodle_Pie-Chart_Crust.svg"
import Chart from "react-apexcharts"

type Props = {
  gameStats: GameStats
}

export const Piechart = ({ gameStats }: Props) => {
  const winDistribution = gameStats.winDistribution
  let labels: string[]= []

  const getData = () => {
    let pieData = [];
    for(let i = 0; i < 6; i++){
      if(winDistribution[i] > 0){
        pieData.push(winDistribution[i])
        labels.push((i+1).toString())
      }
    }
    return pieData;
  }

  let options={
    colors: ["#a2c94c"],
    labels: labels,
    legend: {
      show: false
    },
    dataLabels: {
      formatter: (val: string | number | number[], opts?: any): string | number => {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name] as (unknown) as (string | number);
      },
    },
  }

  return (
    <>
    <div className='relative my-4 mx-auto h-64 w-64 flex justify-center items-center'>
      <Crust className='absolute z-0 inset-0' />
      <div className='absolute z-10 left-1/2 transform -translate-x-1/2'>
        <Chart options={options} series={getData()} type="pie" width="320"/>
      </div>
    </div>
    </>
  )
}