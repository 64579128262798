import { useEffect, useState } from 'react'
import { ApiService } from '../../lib/api'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  handleLogin: () => void
  handleError: () => void
  handleSuccess: () => void
  handleFailure: () => void
  setToken: (val: string) => void
}

interface LoginData {
    email: string
    pin: string
}

export const LoginWithPinModal = ({ isOpen, handleClose, handleLogin, handleError, setToken, handleSuccess, handleFailure }: Props) => {
    const [submitAvail, setToggleSubmitAvail] = useState(false);
    const storedEmail = localStorage.getItem("Email");

    const [data, setData] = useState<LoginData>(() => {
        const initialValue = {
            email: storedEmail || '',
            pin: ''
        }
        return initialValue;
    });

    useEffect(() => {
        if(data.email && data.pin) {
            setToggleSubmitAvail(true);
        } else {
            setToggleSubmitAvail(false);
        }
    }, [data])

    const handleChange = (e: { target: { value: any; name: any } }) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
    };

    const apiService = new ApiService();

    const handleResend = async () => {
        if(storedEmail){
            const loginData = {
                username: storedEmail 
            }

            try{
                await apiService.loginWithEmail(loginData.username);
                handleSuccess()
            }
            catch(e){
                console.error(e);
                handleFailure();
            }
        } else {
            handleFailure()
        }
    }

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        setToggleSubmitAvail(false);
        e.preventDefault();
        const loginData = {
            email: data.email,
            pin: data.pin
        }

        try{
            const res = await apiService.loginWithPin(loginData);
            localStorage.setItem('phoodle-user-token', res.data);
            setToken(res.data);
            handleClose();
            handleLogin();
            setToggleSubmitAvail(true);
        }
        catch(e){
            console.error(e);
            setToggleSubmitAvail(true);
            handleError();
        }
    }

  return (
    <BaseModal title="LOG IN WITH PIN" isOpen={isOpen} handleClose={handleClose}>
        <p className='font-poppins font-light mb-3 dark:text-white'>
            Enter the PIN you received by email and your account email address. <br></br>
            Didn't receive an email? <button onClick={() => handleResend()} className='cursor-pointer text-correctGreen'>Resend PIN</button>
        </p>
        <form onSubmit={handleSubmit}>
            <label htmlFor="email">
                <div className='flex flex-col'>
                    <div className='text-left'>
                        <span className='font-bold dark:text-white'>EMAIL ADDRESS</span>
                    </div>
                    <input
                        type="email"
                        name="email"
                        className='p-1 border-2 rounded-md'
                        value={data.email}
                        onChange={handleChange}
                    />
                </div>
            </label>
            <label htmlFor="pin">
                <div className='flex flex-col mb-2'>
                    <div className='text-left'>
                        <span className='font-bold dark:text-white'>PIN</span>
                    </div>
                    <div className='flex relative'>
                        <input
                            name="pin"
                            className='p-1 w-full border-2 rounded-md text-black'
                            value={data.pin}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </label>
            <button 
                type="button"
                className="disabled:bg-slate-400 disabled:ring-0 mt-1 w-1/2 rounded-md border border-transparent shadow-sm px-4 py-3 bg-buttonGreen text-sm font-bold text-black !outline-none ring-4 ring-offset-2 ring-buttonClick sm:text-sm"
                onClick={handleSubmit}
                disabled={!submitAvail}
                >SUBMIT</button>
        </form>
    </BaseModal>
  )
}

