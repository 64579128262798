import axios from "axios";

interface SignUpObject {
    email: string
    hashedPassword: string
    firstName: string
    lastName: string
    allowsEmail: boolean
    bestStreak: number
    currentStreak: number
    gamesFailed: number
    successRate: number
    winDistribution: number[]
}

interface LoginObject {
    username: string
    password: string
}

interface LoginWithPinObject {
    email: string
    pin: string
}

export class ApiService {

    public async getMysteryWord(day: string): Promise<any> {
        const response = await axios.get(process.env.REACT_APP_BACKEND_URL + day);
        return response.data;
    }

    public async getUserData(jwt: string): Promise<any> {
        if(process.env.REACT_APP_BACKEND_SELF){
            const response = await axios.get(process.env.REACT_APP_BACKEND_SELF, {
                headers: {
                  'Authorization': jwt
                }
              });
            return response.data;
        }
    }

    public async refreshJwt(jwt: string): Promise<any> {
        if(process.env.REACT_APP_BACKEND_REFRESH_TOKEN){
            const response = await axios.get(process.env.REACT_APP_BACKEND_REFRESH_TOKEN, {
                headers: {
                  'Authorization': jwt
                }
              });
            return response.data;
        }
    }

    public async login(data: LoginObject): Promise<any> {
        if(process.env.REACT_APP_BACKEND_LOGIN){
            const response = await axios.post(process.env.REACT_APP_BACKEND_LOGIN, data)
            return response
        }
    }

    public async loginWithEmail(data: string): Promise<any> {
        if(process.env.REACT_APP_BACKEND_LOGIN_WITH_EMAIL){
            const response = await axios.post(process.env.REACT_APP_BACKEND_LOGIN_WITH_EMAIL, data, {
                headers: {"Content-Type": "application/json"}
            })
            return response
        }
    }

    public async loginWithPin(data: LoginWithPinObject): Promise<any> {
        if(process.env.REACT_APP_BACKEND_LOGIN_WITH_PIN){
            const response = await axios.post(process.env.REACT_APP_BACKEND_LOGIN_WITH_PIN, data)
            return response
        }
    }

    public async signUp(data: SignUpObject): Promise<any> {
        if(process.env.REACT_APP_BACKEND_SIGNUP){
            const response = await axios.post(process.env.REACT_APP_BACKEND_SIGNUP, data)
            return response
        }
    }

    public async editData(data: any, id: string, jwt: string): Promise<any> {
        if(process.env.REACT_APP_BACKEND_UPDATE){
            const response = await axios.patch(process.env.REACT_APP_BACKEND_UPDATE + id, 
            {
                Chain: data
            }, 
            { 
                headers: {
                    'Authorization': jwt
                }
            })
            return response
        }
    }
}