import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  handleOpenSignUp: () => void
}

export const InfoModal = ({ isOpen, handleClose, handleOpenSignUp }: Props) => {
  const userToken = localStorage.getItem('phoodle-user-token')
  return (
    <BaseModal title="HOW TO PLAY" isOpen={isOpen} handleClose={handleClose}>
      <div className='divide-y-2 divide-buttonGreen'>
        <div>
          <p className="font-poppins font-light text-sm text-black py-2 dark:text-white">
            Guess the <span className="font-bold">PHOODLE</span> in 6 tries. Each guess is a 5-letter word. The answer is a food-related term
            including cooking techniques, kitchen gadgets, ingredients, and more.
          </p>

          <p className="font-poppins font-light text-sm text-black pb-2 pt-2 dark:text-white">
            After each guess, the color of the tiles will change to indicate how close you are to solving the puzzle. Some examples here:
          </p>

          <div className="flex justify-center mb-1 mt-4">
            <Cell
              isRevealing={true}
              isCompleted={true}
              value="T"
              status="correct"
            />
            <Cell value="A" />
            <Cell value="C" />
            <Cell value="O" />
            <Cell value="S" />
          </div>
          <p className="font-poppins whitespace-nowrap text-xs sm:text-sm font-light text-black dark:text-white">
            The letter T is in the word and in the correct spot.
          </p>

          <div className="flex justify-center mb-1 mt-4">
            <Cell value="F" />
            <Cell value="I" />
            <Cell
              isRevealing={true}
              isCompleted={true}
              value="B"
              status="present"
            />
            <Cell value="E" />
            <Cell value="R" />
          </div>
          <p className="font-poppins font-light whitespace-nowrap text-xs sm:text-sm text-black dark:text-white">
            The letter B is in the word but in the wrong spot.
          </p>

          <div className="flex justify-center mb-1 mt-4">
            <Cell value="B" />
            <Cell value="A" />
            <Cell value="K" />
            <Cell isRevealing={true} isCompleted={true} value="E" status="absent" />
            <Cell value="D" />
          </div>
          <p className="font-poppins font-light whitespace-nowrap text-xs sm:text-sm text-black dark:text-white">
            The letter E is not in the word in any spot.
          </p>

          <p className="font-poppins font-light text-sm text-black pb-2 pt-2 dark:text-white">
            A new PHOODLE will be available each day!
          </p>
        </div>
        {!userToken && 
          <div>
            <p className="font-poppins font-light text-sm text-black py-2 dark:text-white">
              <span className="font-bold">New!</span> <button onClick={()=> {handleClose(); handleOpenSignUp()}} className='text-darkerGreen cursor-pointer focus:outline-none'>Create a free PHOODLE account</button>
              <br></br>to save your progress allowing you to play PHOODLE from other devices while retaining your stats.
            </p>
          </div>
        }
      </div>
    </BaseModal>
  )
}
