import { StatBar } from '../stats/StatBar'
import { Piechart } from '../stats/Piechart'
import { GameStats } from '../../lib/localStorage'
import { BaseModal } from './BaseModal'


type Props = {
  isOpen: boolean
  handleClose: () => void
  handleOpenLogin: () => void
  handleOpenSignUp: () => void
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
  userAPIStats?: UserData
  logout: () => void
}

interface UserData {
  _id: string
  email: string
  firstName: string
  lastName: string
  allowsEmail: boolean
  isSuperUser: boolean
  gamesFailed: number
  successRate: number
  currentStreak: number
  bestStreak: number
  winDistribution: number[]
}

export const StatsModal = ({
  isOpen,
  handleClose,
  gameStats,
  numberOfGuessesMade,
  handleOpenLogin,
  handleOpenSignUp,
  userAPIStats,
  logout
}: Props) => {
  const winDistribution = gameStats.winDistribution

  if(userAPIStats) {
    const apiDistribution = userAPIStats.winDistribution
    const { 
      winDistribution,
      gamesFailed,
      currentStreak,
      bestStreak,
      successRate
    } = {...userAPIStats}

    const failed = gamesFailed
    const success = winDistribution.reduce((partialSum, a) => partialSum + a, 0)
    const totalGames = failed + success

    const apiStats: GameStats = {winDistribution, gamesFailed, currentStreak, bestStreak, totalGames, successRate}

    return (
      <BaseModal
        title="STATISTICS"
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <StatBar gameStats={apiStats} />
          <h4 className="text-lg leading-6 font-medium font-extrabold text-gray-900 my-4 dark:text-gray-100">
            GUESS DISTRIBUTION
          </h4>
          <div className='flex divide-x-2 justify-center dark:text-white'>
            <p className='px-2'><span className='font-extrabold'>1: </span>{apiDistribution[0]}</p>
            <p className='px-2'><span className='font-extrabold'>2: </span>{apiDistribution[1]}</p>
            <p className='px-2'><span className='font-extrabold'>3: </span>{apiDistribution[2]}</p>
            <p className='px-2'><span className='font-extrabold'>4: </span>{apiDistribution[3]}</p>
            <p className='px-2'><span className='font-extrabold'>5: </span>{apiDistribution[4]}</p>
            <p className='px-2'><span className='font-extrabold'>6: </span>{apiDistribution[5]}</p>
          </div>
          <div className='divide-y-2'>
            <Piechart
              gameStats={apiStats}
            />
            <div className='flex flex-row justify-center items-center divide-x-2 pt-2 dark:text-white'>
              <div className='px-2'>
                Logged in as <span className='font-extrabold'>{userAPIStats.firstName} {userAPIStats.lastName}</span> 
              </div>
              <div className='px-2 cursor-pointer' onClick={logout}>
                <span className='text-darkerGreen'>Sign Out</span>
              </div>
            </div>
          </div>
      </BaseModal>
    )
  }
  else if (gameStats.totalGames <= 0) {
    return (
      <BaseModal
        title="STATISTICS"
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <div className='divide-y-2'>
          <StatBar gameStats={gameStats} />
          <div className='pt-2 dark:text-white'>
            <span className='font-bold'>Stats not loading?</span> <button onClick={()=> {handleClose(); handleOpenLogin()}} className='text-darkerGreen cursor-pointer focus:outline-none'>Log in</button> or <button onClick={()=> {handleClose(); handleOpenSignUp()}} className='text-darkerGreen cursor-pointer focus:outline-none'>create an account</button> to ensure your statistics are saved.
          </div>
        </div>
      </BaseModal>
    )
  }
  return (
    <BaseModal
      title="STATISTICS"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <StatBar gameStats={gameStats} />
      <h4 className="text-lg leading-6 font-medium font-extrabold text-gray-900 my-4 dark:text-gray-100">
        GUESS DISTRIBUTION
      </h4>
      <div className='flex divide-x-2 justify-center dark:text-white'>
        <p className='px-2'><span className='font-extrabold'>1: </span>{winDistribution[0]}</p>
        <p className='px-2'><span className='font-extrabold'>2: </span>{winDistribution[1]}</p>
        <p className='px-2'><span className='font-extrabold'>3: </span>{winDistribution[2]}</p>
        <p className='px-2'><span className='font-extrabold'>4: </span>{winDistribution[3]}</p>
        <p className='px-2'><span className='font-extrabold'>5: </span>{winDistribution[4]}</p>
        <p className='px-2'><span className='font-extrabold'>6: </span>{winDistribution[5]}</p>
      </div>
      <div className='divide-y-2'>
        <Piechart
          gameStats={gameStats}
        />
        <div className='pt-2 dark:text-white'>
          <span className='font-bold'>Stats not loading?</span> <button onClick={()=> {handleClose(); handleOpenLogin()}} className='text-darkerGreen cursor-pointer focus:outline-none'>Log in</button> or <button onClick={()=> {handleClose(); handleOpenSignUp()}} className='text-darkerGreen cursor-pointer focus:outline-none'>create an account</button> to ensure your statistics are saved.
        </div>
      </div>
    </BaseModal>
  )
}