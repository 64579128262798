import { useEffect, useState } from 'react'
import { ApiService } from '../../lib/api'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
  handleLogin: () => void
  handleError: () => void
  handleOpenLoginWithPinModal: () => void
}

interface LoginData {
    email: string
}

export const LoginWithEmailModal = ({ isOpen, handleClose, handleLogin, handleOpenLoginWithPinModal, handleError }: Props) => {
    const [submitAvail, setToggleSubmitAvail] = useState(false)

    const [data, setData] = useState<LoginData>({
        email: ''
    })

    useEffect(() => {
        if(data.email) {
            setToggleSubmitAvail(true);
        } else {
            setToggleSubmitAvail(false);
        }
    }, [data])

    const handleChange = (e: { target: { value: any; name: any } }) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
    };


    const apiService = new ApiService();

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        setToggleSubmitAvail(false);
        e.preventDefault();
        const loginData = {
            username: data.email
        }

        try{
            await apiService.loginWithEmail(loginData.username);
            localStorage.setItem('Email', loginData.username);
            handleLogin();
            handleClose();
            handleOpenLoginWithPinModal();
            setToggleSubmitAvail(true);
        }
        catch(e){
            console.error(e);
            setToggleSubmitAvail(true);
            handleError();
        }
    }

  return (
    <BaseModal title="Forgot Password?" isOpen={isOpen} handleClose={handleClose}>
        <p className='font-poppins font-light mb-3 dark:text-white'>
            Enter your email to receive a PIN. <br></br> You may also click the link in the email to be logged in directly.
        </p>
        <form onSubmit={handleSubmit}>
            <label htmlFor="email">
                <div className='flex flex-col mb-4'>
                    <div className='text-left'>
                        <span className='font-bold dark:text-white'>EMAIL</span>
                    </div>
                    <input
                        type="email"
                        name="email"
                        className='p-1 border-2 rounded-md'
                        value={data.email}
                        onChange={handleChange}
                    />
                </div>
            </label>
            <button 
                type="button"
                className="disabled:bg-slate-400 disabled:ring-0 mt-1 w-1/2 rounded-md border border-transparent shadow-sm px-4 py-3 bg-buttonGreen text-sm font-bold text-black !outline-none ring-4 ring-offset-2 ring-buttonClick sm:text-sm"
                onClick={handleSubmit}
                disabled={!submitAvail}
            >
                SUBMIT
            </button>
        </form>
    </BaseModal>
  )
}

