import {
  CogIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline'
import { ReactComponent as ChefsHat } from '../../images/Phoodle_Chefs-Hat_Icon_200.svg'
import { ReactComponent as PieChartLogo} from "../../images/Phoodle_Stats_and_Account.svg"
import { ReactComponent as LightLogo } from "../../images/Phoodle_Logo.svg"
import { ReactComponent as DarkLogo } from "../../images/Phoodle_Dark_Logo.svg"


type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
  setIsFactModalOpen: (value: boolean) => void
  lightLogoOverride: string
  darkLogoOverride: string
  isDarkMode: boolean
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
  setIsFactModalOpen,
  lightLogoOverride,
  darkLogoOverride,
  isDarkMode
}: Props) => {

  return (
    <div className="navbar">
      <div className="navbar-content px-5 md:my-2">
        <div className='left-icons w-1/4 md:w-auto'>
          <PieChartLogo 
            className="h-8 w-8 md:h-8 md:w-8 mr-3 cursor-pointer dark:fill-white"
            onClick={() => setIsStatsModalOpen(true)}
          />
          <QuestionMarkCircleIcon
            className="h-8 w-8 md:h-8 md:w-8 mr-2 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
        </div>
        <div className="w-1/2 md:w-80">
          {isDarkMode ?
            <>{darkLogoOverride ? <img src={darkLogoOverride} alt='Phoodle Dark Logo'/> : <DarkLogo />}</>
            :
            <>{lightLogoOverride ? <img src={lightLogoOverride} alt='Phoodle Light Logo'/> : <LightLogo />}</>
          }
        </div>
        <div className="right-icons w-1/4 md:w-auto">
          <ChefsHat
            className="h-8 w-8 md:h-8 md:w-8 mr-2 ml-3 cursor-pointer fill-white stroke-black dark:stroke-white dark:fill-background"
            onClick={() => setIsFactModalOpen(true)}
          />
          <CogIcon
            className="h-8 w-8 md:h-8 md:w-8 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
