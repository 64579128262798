const Support = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center align-items items-center">
      <h1 className="font-bold text-4xl my-12">Phoodle Support Page</h1>
      <p className="my-2">Email: <a className="text-buttonGreen" href="mailto:hello@phoodle.net">hello@phoodle.net</a></p>
      <p className="my-2">Business Address: 19 East 72nd Street, NY 10021</p>
      <p className="my-2">Phone Number: <span className="text-buttonGreen">917-208-3038</span></p>
    </div>
  );
};

export default Support;
