import { useEffect, useState } from 'react'
import { useAlert } from '../../context/AlertContext'
import { ApiService } from '../../lib/api'
import { loadStatsFromLocalStorage } from '../../lib/localStorage'
import { BaseModal } from './BaseModal'
import validator from 'validator'

type Props = {
  isOpen: boolean
  handleClose: () => void
  handleSignUp: () => void
  handleError: () => void
  setToken: (val: string) => void
}

interface SignUpData {
    email: string
    hashedPassword: string
    firstName: string
    lastName: string
    allowsEmail: boolean
    bestStreak: number
    currentStreak: number
    gamesFailed: number
    successRate: number
    totalGames: number
    winDistribution: number[]
}

export const SignUpModal = ({ isOpen, handleClose, handleSignUp, handleError, setToken }: Props) => {
    const [passwordShow, setPasswordShow] = useState(false)
    const [submitAvail, setToggleSubmitAvail] = useState(false)
    const { showError: showErrorAlert } = useAlert()
    const [emailError, setEmailError] = useState('')
    const [spaceError, setSpaceError] = useState('')

    const togglePasswordVisibility = () => {
        setPasswordShow(!passwordShow)
    }
    
    const [data, setData] = useState<SignUpData>(()=> {
        const savedStats = loadStatsFromLocalStorage();
        let initialState: SignUpData = {
            email: '',
            hashedPassword: '',
            firstName: '',
            lastName: '',
            allowsEmail: false,
            bestStreak: 0,
            currentStreak: 0,
            gamesFailed: 0,
            successRate: 0,
            totalGames: 0,
            winDistribution: [0,0,0,0,0,0]
        }
        if(savedStats === null){
            return initialState
        } 
        initialState.bestStreak = savedStats.bestStreak
        initialState.currentStreak = savedStats.currentStreak
        initialState.gamesFailed = savedStats.gamesFailed
        initialState.successRate = savedStats.successRate
        initialState.totalGames = savedStats.totalGames
        initialState.winDistribution = savedStats.winDistribution
        
        return initialState
    })
    
    useEffect(() => {
        if(data.firstName && data.lastName && data.email && data.hashedPassword && !emailError && !spaceError) {
            setToggleSubmitAvail(true);
        } else {
            setToggleSubmitAvail(false);
        }
    }, [data, emailError, spaceError])

    const handleChange = (e: { target: { value: any; name: any } }) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        })
        if(e.target.name === 'email'){
            if (!validator.isEmail(value)) {
                setEmailError('Please enter a valid Email')
            } else {
                setEmailError('')
            }
        }
        if(e.target.name === 'firstName'){
            if (value.indexOf(' ') >= 0) {
                setSpaceError('Please check fields for spaces')
            } else {
                setSpaceError('')
            }
        }
        if(e.target.name === 'lastName'){
            if (value.indexOf(' ') >= 0) {
                setSpaceError('Please check fields for spaces')
            } else {
                setSpaceError('')
            }
        }
        if(e.target.name === 'hashedPassword'){
            if (value.indexOf(' ') >= 0) {
                setSpaceError('Please check fields for spaces')
            } else {
                setSpaceError('')
            }
        }
    };

    const handleCheckboxChange = () => {
        const value = data.allowsEmail
        setData({
            ...data,
            allowsEmail: !value
        })
    }

    const apiService = new ApiService();

    const toggleSubmit = () => {
        setToggleSubmitAvail(!submitAvail)
    }

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        toggleSubmit()
        e.preventDefault()

        const signUpData = {
            email: data.email,
            hashedPassword: data.hashedPassword,
            firstName: data.firstName,
            lastName: data.lastName,
            allowsEmail: data.allowsEmail,
            bestStreak: data.bestStreak,
            currentStreak: data.currentStreak,
            gamesFailed: data.gamesFailed,
            successRate: data.successRate,
            totalGames: data.totalGames,
            winDistribution: data.winDistribution
        }

        if(!signUpData.email && !signUpData.hashedPassword){
            return
        }

        try{
            const res = await apiService.signUp(signUpData);
            localStorage.setItem('phoodle-user-token', res.data);
            setToken(res.data);
            handleClose();
            handleSignUp();
        }
        catch(res: any){
            setToggleSubmitAvail(true);
            showErrorAlert(res.response.data.message)
        }
    }

  return (
    <BaseModal title="CREATE AN ACCOUNT" isOpen={isOpen} handleClose={handleClose}>
        <p className='font-poppins font-light mb-3 dark:text-white'>
            Create an account to ensure your statistics are saved. Existing stats on this device will be stored to your new account.
        </p>
        <form onSubmit={handleSubmit} className='dark:text-white'>
            <label htmlFor="first name">
                <div className='flex flex-col'>
                    <div className='text-left'>
                        <span className='font-bold'>FIRST NAME</span>
                    </div>
                    <input
                        type="name"
                        name="firstName"
                        className='p-1 border-2 rounded-md text-black'
                        required
                        value={data.firstName}
                        onChange={handleChange}
                    />
                </div>
            </label>
            <label htmlFor="last name">
                <div className='flex flex-col'>
                    <div className='text-left'>
                        <span className='font-bold'>LAST NAME</span>
                    </div>
                    <input
                        type="name"
                        name="lastName"
                        className='p-1 border-2 rounded-md text-black'
                        value={data.lastName}
                        onChange={handleChange}
                    />
                </div>
            </label>
            <label htmlFor="email">
                <div className='flex flex-col'>
                    <div className='text-left'>
                        <span className='font-bold'>EMAIL</span>
                    </div>
                    <input
                        type="email"
                        name="email"
                        className='p-1 border-2 rounded-md text-black'
                        value={data.email}
                        onChange={handleChange}
                    />
                    <span style={{
                    fontWeight: 'bold',
                    color: 'red',
                    }}>{emailError}</span>
                </div>
            </label>
            <label htmlFor="hashedPassword">
                <div className='flex flex-col mb-2'>
                    <div className='text-left'>
                        <span className='font-bold'>PASSWORD</span>
                    </div>
                    <div className='flex relative'>
                        <input
                            type={passwordShow ? "text" : "password"}
                            name="hashedPassword"
                            className='p-1 w-full border-2 rounded-md text-black'
                            value={data.hashedPassword}
                            onChange={handleChange}
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                            {passwordShow ? 
                                <button type="button" onClick={togglePasswordVisibility}>
                                    <svg fill="none" className="h-6 text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                        <path fill="currentColor"
                                            d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z">
                                        </path>
                                    </svg>
                                </button>
                                :
                                <button type="button" onClick={togglePasswordVisibility}>
                                    <svg className="h-6 text-gray-700" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                        <path fill="currentColor"
                                            d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z">
                                        </path>
                                    </svg>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </label>
            <label htmlFor="allowsEmail">
                <div className='flex flex-row mb-2 justify-between'>
                    <div className='text-left'>
                        <span className='font-bold'>ALLOW PHOODLE UPDATES</span>
                    </div>
                    <input
                        type="checkbox"
                        className='accent-buttonGreen h-5 w-5  rounded-lg'
                        checked={data.allowsEmail}
                        onChange={handleCheckboxChange}
                    />
                </div>
            </label>
            <span style={{
                fontWeight: 'bold',
                color: 'red',
            }}>{spaceError}</span>
            <button 
                type="button"
                className="disabled:bg-slate-400 mt-1 w-1/2 rounded-md border border-transparent shadow-sm px-4 py-3 bg-buttonGreen text-sm font-bold text-black !outline-none disabled:ring-0 ring-4 ring-offset-2 ring-buttonClick sm:text-sm"
                onClick={handleSubmit}
                disabled={!submitAvail}
                >
            SUBMIT
            </button>
        </form>
    </BaseModal>
  )
}
